<template>
  <div class="page">
    <img src="@i/login/login.png" class="left-img" />
    <div class="right-content-container">
      <!-- 注册成功 -->
      <span class="title" v-if="!isSuccess">注册抖脉账号</span>
      <div v-if="isSuccess" class="success-container">
        <img src="@i/login/success.png" />
        <span class="ft24 text-cl-title mt40">恭喜你，注册成功</span>
        <router-link to="/login" class="mt55">
          <span class="button">返回登录</span>
        </router-link>
      </div>
      <!-- 注册 -->
      <div class="content-container" v-else>
        <div class="content-container-form">
          <a-form>
            <a-form-item
              label=""
              :validate-status="params.mobilePhone.help ? 'error' : ''"
              :help="params.mobilePhone.help"
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input
                placeholder="请输入手机号"
                v-model="params.mobilePhone.value"
                @change="onCheckChange"
                style="width:336px;height:44px"
              >
              </a-input>
            </a-form-item>
            <a-form-item
              label=""
              :validate-status="params.companyName.help ? 'error' : ''"
              :help="params.companyName.help"
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
              type="companyName"
            >
              <a-input
                placeholder="请输入公司名"
                v-model="params.companyName.value"
                style="width:336px;height:44px"
                class=""
              >
              </a-input>
            </a-form-item>
            <a-form-item
              label=""
              :validate-status="params.password.help ? 'error' : ''"
              :help="params.password.help"
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
              type="password"
            >
              <a-input
                placeholder="请输入密码"
                v-model="params.password.value"
                type="password"
                style="width:336px;height:44px"
                class=""
              >
              </a-input>
            </a-form-item>
            <a-form-item
              label=""
              :validate-status="params.confirmPassword.help ? 'error' : ''"
              :help="params.confirmPassword.help"
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
              type="confirmPassword"
            >
              <a-input
                placeholder="请再次输入密码"
                v-model="params.confirmPassword.value"
                type="password"
                style="width:336px;height:44px"
                class=""
              >
              </a-input>
            </a-form-item>
            <a-form-item
              label=""
              :validate-status="params.captcha.help ? 'error' : ''"
              :help="params.captcha.help"
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              <div class="captcha-container">
                <a-input
                  placeholder="请输入验证码"
                  style="height:44px"
                  @keyup.enter="onRegisteredClick"
                  v-model="params.captcha.value"
                >
                </a-input>
                <span
                  class="captcha-button"
                  @click="onCaptchaClick"
                  v-if="waitTime > 59 && hadPhone"
                  >发送验证码
                </span>
                <span
                  class="captcha-count-down-button g-disable-button"
                  v-else-if="waitTime > 59 && !hadPhone"
                  >发送验证码
                </span>
                <span
                  class="captcha-count-down-button g-disable-button"
                  v-else
                  disable
                  >{{ waitTime }}s后可重新获取
                </span>
              </div>
            </a-form-item>
          </a-form>
          <span
            :class="
              hadPhone && isAgree && params.captcha.value
                ? 'registered-button'
                : 'registered-disable-button g-disable-button'
            "
            @click="onRegisteredClick"
          >
            免费注册
          </span>
          <div class="g-flex-row mt15">
            <span>
              注册即表示同意抖脉
              <span
                class="text-cl-pointer pointer"
                @click="$openUserAgreement()"
                >《用户服务协议》
              </span></span
            >
          </div>
          <div class="g-flex-row mt15">
            <span>已有账号?</span>
            <router-link to="/login">
              <span class="button">快速登录</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Statistical from '@l/statistical'
import regular from '@l/regular'
export default {
  created () {},
  methods: {
    // ------------------ 点击方法
    onCaptchaClick () {
      this.sendRegisterCaptcha()
    },
    onCheckChange () {
      if (regular.regPhone.test(this.params.mobilePhone.value)) {
        this.hadPhone = true
      } else {
        this.hadPhone = false
      }
    },
    onCheckboxChange (e) {
      this.isAgree = e.target.checked
    },
    onRegisteredClick () {
      if (!this.isAgree) {
        return
      }
      var withoutError = true
      if (!regular.regPhone.test(this.params.mobilePhone.value)) {
        withoutError = false
        this.params.mobilePhone.help = '请输入正确格式的手机号码'
      }
      if (!this.params.mobilePhone.value) {
        withoutError = false
        this.params.mobilePhone.help = '请输入手机号码'
      }
      if (!this.params.companyName.value) {
        withoutError = false
        this.params.companyName.help = '请输入公司名称'
      }
      if (!this.params.password.value) {
        withoutError = false
        this.params.password.help = '请输入密码'
      }
      if (!regular.regPassword.test(this.params.password.value)) {
        withoutError = false
        this.params.password.help = '请输入正确格式的密码'
      }
      if (!this.params.confirmPassword.value) {
        withoutError = false
        this.params.confirmPassword.help = '请输入确认密码'
      }
      if (this.params.confirmPassword.value !== this.params.password.value) {
        withoutError = false
        this.params.confirmPassword.help = '两次密码不一致'
      }
      if (!regular.regCaptcha.test(this.params.captcha.value)) {
        withoutError = false
        this.params.captcha.help = '请输入正确格式的验证码'
      }
      if (!this.params.captcha.value) {
        withoutError = false
        this.params.captcha.help = '请输入验证码'
      }
      if (!withoutError) {
        return
      }
      Statistical.register.toregister()
      this.params.companyName.help = ''
      this.params.mobilePhone.help = ''
      this.params.captcha.help = ''
      this.params.confirmPassword.help = ''
      this.params.password.help = ''
      this.reqRegister()
    },
    // ------------------ 请求方法
    sendRegisterCaptcha () {
      this.$api.account
        .sendRegisterCaptcha({ mobilePhone: this.params.mobilePhone.value })
        .then(res => {
          this.countdown()
        })
        .catch(res => {})
    },
    // getSalt () {
    //   this.$api.account
    //     .getSalt({ mobilePhone: this.params.mobilePhone.value, type: 1 })
    //     .then(res => {
    //       this.reqRegister(res)
    //     })
    //     .catch(res => {
    //       if (res > 0) {
    //         this.isError = true
    //       }
    //     })
    // },
    reqRegister (salt) {
      this.$api.account
        .reqRegister({
          companyName: this.params.companyName.value,
          mobilePhone: this.params.mobilePhone.value,
          password: this.params.password.value,
          confirmPassword: this.params.confirmPassword.value,
          captcha: this.params.captcha.value
        })
        .then(res => {
          this.isSuccess = true
          Statistical.register.registerSuccess()
          localStorage.setItem('mobilePhone', this.params.mobilePhone.value)
          this.showMerchantInfo = true
        })
        .catch(res => {})
    },
    // ------------------ 其它
    countdown () {
      const _this = this
      if (this.waitTime === 0) {
        this.waitTime = 60
        // clearTimeout(this.countDownTime)
      } else {
        this.waitTime--
        setTimeout(function () {
          _this.countdown()
        }, 1000)
      }
    },
    openUserAgreement () {
      Statistical.register.openUserAgreement()
    },
    quicklogon () {
      Statistical.register.quicklogon()
    },
    indexClick () {
      Statistical.register.indexClick()
    }
  },
  data () {
    return {
      waitTime: 60,
      workerNo: '',
      isSuccess: false,
      showMerchantInfo: false,
      showApplyService: false,
      hadPhone: false,
      isAgree: true,
      params: {
        mobilePhone: {
          value: '',
          help: ''
        },
        companyName: {
          value: '',
          help: ''
        },
        password: {
          value: '',
          help: ''
        },
        confirmPassword: {
          value: '',
          help: ''
        },
        captcha: {
          value: '',
          help: ''
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.left-img {
  height: 100vh;
}
.right-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #fff;
  height: 100%;
  .right-top-img {
    height: 208px;
    width: 197px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .left-bottom-img {
    height: 44px;
    width: 69px;
    position: absolute;
    bottom: 264px;
    left: 37px;
  }
  .index-button {
    color: #4690ff;
    font-size: 16px;
    position: absolute;
    top: 47px;
    right: 44px;
    z-index: 2;
    .pointer;
  }
}
.content-container {
  width: 568px;
  border-radius: 4px;
  padding: 44px 34px 0px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 14px;
  }
  .captcha-container {
    .g-flex-row;
    width: 336px;
  }
  .captcha-button {
    height: 44px;
    line-height: 44px;
    padding: 0 10px;
    border-radius: 0 6px 6px 0;
    border: 1px solid #2878ff;
    // border-radius: 2px;
    color: #2878ff;
    flex-shrink: 0;
    .pointer;
  }
  .captcha-count-down-button {
    height: 44px;
    line-height: 44px;
    padding: 0 10px;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 2px;
    flex-shrink: 0;
  }
  .registered-button {
    width: 330px;
    height: 56px;
    background: #2878ff;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @cl-main;
    border-radius: 2px;
    color: #ffffff;
    flex-shrink: 0;
    .ft16;
    .pointer;
  }
  .registered-disable-button {
    width: 330px;
    height: 56px;
    background: #eee;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: not-allowed;
    .ft16;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img-container {
  height: 180px;
  width: 568px;
  margin-top: 170px;
  background-image: url(../../assets/images/login/banner.png);
  position: relative;
  .qr-code {
    height: 111px;
    width: 111px;
    position: absolute;
    top: 26px;
    right: 36px;
  }
}
.record-container {
  color: #999;
  margin-top: 110px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  .record-icon {
    width: 18px;
    height: 20px;
  }
}
.success-container {
  padding: 200px 0 60px;
  border-radius: 11px;
  margin-top: 15px;
  box-shadow: 1px 5px 62px 0px rgba(148, 148, 148, 0.18);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // justify-content: center;
  align-items: center;
  img {
    width: 480px;
    height: 210px;
  }
  .button {
    height: 40px;
    padding: 18px;
    color: #fff;
    background: @cl-main;
    border-radius: 4px;
    margin-top: 20px;
    .ft14;
    .pointer;
    .g-flex-center;
  }
}
.link-button {
  padding: 0 15px;
  .pointer;
}
.ant-form-explain {
  width: 100%;
}
.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 22px;
  color: #868998;
}
</style>
